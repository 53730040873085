import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import Wrapper from "./context/GlobalWrapper";
// Assurez-vous que cette importation est correcte
import { store } from "./redux/store";
import "./index.css";
import Loading from "./components/common/Loading";


const App = React.lazy(() => import("./App"));
const LoginPage = React.lazy(() => import("./pages/Login"));
const RegisterPage = React.lazy(() => import("./pages/Register"));
const HomePage = React.lazy(() => import("./pages/Home"));
const News = React.lazy(() => import("./pages/news"));
ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <Wrapper>
        <ChakraProvider>
          <Router>
            <Routes>
              <Route path="/home" element={<App />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/news" element={<News/>} />
            </Routes >
          </Router>
        </ChakraProvider>
      </Wrapper>
    </Suspense>

  </Provider>
);
