const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  id: "",
  fullname: "",
  email: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder.addCase("auth/login/fulfilled", (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.fullname = action.payload.fullname;
    });
  },
});

export default authSlice.reducer;
