import { createContext, useState } from 'react';
import axios from 'axios';
import { useDisclosure, useToast  } from '@chakra-ui/react';
export const GlobalContext = createContext();

export default function Wrapper({ children }) {

  const [laws, setLaws] = useState([]);
  const [errors, setErrors] = useState({});
  const [foundLaw, setFoundLaw] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const FetchLaws = () => {
    axios
      .get('/api/laws')
      .then((res) => {
        setLaws(res.data);
      })
      .catch((err) => {
        console.log(err.reponse.data);
      });
  };

  const Search = (query) => {
    axios
      .post(`/api/laws/search?key=${query}`)
      .then((res) => {
        setLaws(res.data);
      })
      .catch((err) => {
        console.log(err.reponse.data);
      });
  };

  const Delete = (id) => {
    axios
      .delete(`/api/laws/${id}`)
      .then((res) => {
        setLaws(laws.filter((u) => u._id !== id));
        toast({
          colorScheme:'blue',
          title: 'Law Deleted',
          status: 'success',
          duration: 4000,
          isClosable: true,
          
        });
      })
      .catch((err) => {
        console.log(err.reponse.data);
      });
  };

  const Add = (form, setForm) => {
    axios
      .post('/api/laws', form)
      .then((res) => {
        setLaws([res.data,...laws]);
        toast({
          title: 'Law Added',
          colorScheme: 'purple',
          status: 'success',
          duration: 4000,
          isClosable: true,
         
        });
        setErrors({});
        setForm({});
        onClose();
      })
      .catch((err) => {
        setErrors(err.response.data.error);
      });
  };

  const FindOne = async (id) => {
    await axios
      .get(`/api/laws/${id}`)
      .then((res) => {
        setFoundLaw(res.data);
      })
      .catch((err) => {
        setErrors(err.response.data.error);
      });
  };

  const Update = (form, setForm, id) => {
    axios
      .put(`/api/laws/${id}`, form)
      .then((res) => {
        toast({
          title: 'Law Updated',
          status: 'success',
          duration: 4000,
          isClosable: true,
          colorScheme: 'purple',
        });
        setErrors({});
        setForm({});
        onClose();
        FetchLaws();
      })
      .catch((err) => {
        setErrors(err.response.data.error);
      });
  };
  return (
    <GlobalContext.Provider
      value={{
        FetchLaws,
        Search,
        Delete,
        Add,
        FindOne,
        Update,
        laws,
        onOpen,
        isOpen,
        onClose,
        errors,
        setErrors,
        setLaws,
        foundLaw
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
